
import { RoleTypes, SystemRole } from "@/store/modules/role/role.types";
import { Pagination } from "@/types/types";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const roleX = namespace("Role");

@Component({})
export default class RoleSelect extends Vue {
  @Prop() public label?: string;
  @Prop() public filled?: boolean;
  @Prop() public outlined?: boolean;
  @Prop() public dense?: boolean;
  @Prop() public disabled?: boolean;
  @Prop() public hideDetails?: boolean;
  @Prop() public rules?: unknown[];
  @Prop() value?: number;

  @roleX.Action(RoleTypes.LOAD_ROLES)
  public getRoles!: (pagination: Pagination) => void;

  @roleX.State(RoleTypes.ROLES)
  public roles!: SystemRole[];

  @roleX.State(RoleTypes.LOADING_ROLES_STATE)
  public loadingRoles!: boolean;

  @Emit("input")
  save(id: string): string {
    return id;
  }

  mounted(): void {
    this.getRoles({ page: 1, limit: 10 });
  }
}
